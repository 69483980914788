export const EXPENSE_DATA_NOT_PROVIDED = 'Expense Data Not Provided'
export const EXPENSE_DATA_SOURCES = ['Owner', 'Buyer', 'Developer', 'Borrower', EXPENSE_DATA_NOT_PROVIDED]

export const EXPENSE_DATA_SOURCE_OPTIONS = {
  OWNER: 'Owner',
  BUYER: 'Buyer',
  DEVELOPER: 'Developer',
  BORROWER: 'Borrower',
  NOT_PROVIDED: EXPENSE_DATA_NOT_PROVIDED,
}
